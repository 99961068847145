import { Password } from "@mui/icons-material";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const PasswordResetConfirm = () => {
    const cacheBuster = Date.now();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [sentMessage, setSentMessage] = useState("");

    const { uid, token } = useParams();

    const UpdatePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/users/reset_password_confirm/?cacheBuster=${cacheBuster}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    new_password: newPassword,
                    re_new_password: confirmPassword,
                    token: token,
                    uid: uid
                }),
            });

            if (response.ok) {

                setSentMessage("Password reset successfully. You may now use the new password on your next sign in.");
                toast.success("Password reset successfully.");
            } else {
                const data = await response.json();
                if (data.token || data.uid) {
                    toast.error("Invalid or expired reset link.", { position: "top-right", autoClose: 3000 });
                } else {
                    toast.error("An error occurred. Please try again.", { position: "top-right", autoClose: 3000 });
                }
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again.");
        }
    };


    return (
        <div className="page-signin ng-scope">
            <ToastContainer />
            <div className="signin-body">
                <div className="row">
                    <div className="col col-md-5 signin-logo">
                        <img height="236" width="200" src="/images/alf-logo.png" className="forgot-logo" />
                    </div>
                    <div className="col col-md-7 signin-form">
                        <div>
                            {sentMessage && <div className="successMessage">{sentMessage}</div>}
                        </div>

                        <form className="form-horizontal ng-dirty ng-valid-parse ng-valid ng-valid-required" onSubmit={UpdatePassword}>
                            <fieldset>
                                <div className="form-group">
                                    <span className="glyphicon glyphicon-lock"></span>
                                    <input
                                        type="password"
                                        className="form-control input-lg ng-dirty ng-valid-parse ng-valid ng-valid-required ng-touched"
                                        required
                                        autoFocus
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        disabled={sentMessage !== ""}
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="glyphicon glyphicon-lock"></span>
                                    <input
                                        type="password"
                                        className="form-control input-lg ng-dirty ng-valid-parse ng-valid ng-valid-required ng-touched"
                                        required
                                        autoFocus
                                        placeholder="New Password Again"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        disabled={sentMessage !== ""}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col col-md-5">
                                            <button
                                                className="btn btn-primary submit-email"
                                                type="submit"
                                                disabled={sentMessage !== ""}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetConfirm;